import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import StyledHeading from "../components/StyledHeading"

const ErrorPage = ({ data }) => (
  <Layout>
    <SEO title="404" description="" />
    <div className="flex items-center justify-center h-screen">
      <StyledHeading tag="h1" text="404" size="text-3xl" />
    </div>
  </Layout>
)

export default ErrorPage
